import { createObservableDataAction, IAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetAllEntitiesInput } from './DataActionExtension.g';
import { IBYOBModel } from './DataServiceEntities.g';
/**
 * Get Warranty Info Data Action
 */
export default createObservableDataAction({
    action: <IAction<IBYOBModel[]>>retailAction,
    input: (context: ICreateActionContext) => {
        return createGetAllEntitiesInput({Paging:{Top:50}});
    }
});